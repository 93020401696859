import React from 'react';
import ParkrunIcon from '../images/parkrun.png';
import TrackIcon from '../images/track.png';
import RoadIcon from '../images/road.png';
import MultiIcon from '../images/multi.png';
import GrassIcon from '@mui/icons-material/Grass';
import FilterHdrOutlinedIcon from '@mui/icons-material/FilterHdrOutlined';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';

export const VERSION = 1.6;
export const ISNATIVE = Capacitor.isNativePlatform();
export const HOST = ISNATIVE ?
                                 "https://drastic.run" :
                                 window.location.protocol + "//" + window.location.hostname + ":" + window.location.port;


const userAgent = navigator.userAgent || navigator.vendor;
export const ISANDROIDWEB = !ISNATIVE && /android/i.test(userAgent);
export const ISIOSWEB = !ISNATIVE && (/iPad|iPhone|iPod/.test(userAgent) || (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(userAgent)))

// Gets the name of the platform, such as android, ios, or web
export const PLATFORM = Capacitor.getPlatform();

// Returns a string that identifies device and operating system
// Possible options:
// - android|web
// - android|app
// - ios|web
// - ios|app
// - pc|web
export function getOS() {
    const ops = ISNATIVE ? Capacitor.getPlatform() : (ISANDROIDWEB ? "android" : (ISIOSWEB ? "ios" : "pc"));
    const web_app = ISNATIVE ? "app" : "web";
    return ops + "|" + web_app;
}

export function numberWithCommas(x) {
    return x;
    // return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

function pad2(num) {
    return num < 10 ? "0" + num : num;
}

const USE_DEC = { '100': true, '200': true, '300': true, '400': true, '600': true, '800': true, '1000': true, '1500': true, 'Mile': true, '2000': true, '3000': true, '3000SC': true, '5000': true, '10000': true };

// Events for which we can have personal bests
const MILEDIST = 1.609344
export const IS_ROAD = 1
export const IS_TRACK = 2
export const ALL_EVENTS = ['100', '200', '300', '400', '600', '800', '1000', '1500', '1M', 'Mile', '2000', '2K', '3000', '3K',
              '3000SC', '2M', '3M', 'parkrun', '5K', '5000', '5M', '10K', '10000', '7M', '10M', '20K', 'HM', '16M',
              '30K', '20M', 'Mar']
export const EVENT_TYPES = [2, 2, 2, 2, 2, 2, 2, 2, 1, 2, 2, 1, 2, 1, 2, 1, 1, 1, 1, 2, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1]
export const EVENT_DIST = [0.1, 0.2, 0.3, 0.4, 0.6, 0.8, 1, 1.5, MILEDIST, MILEDIST, 2, 2, 3, 3,
    3, 2*MILEDIST, 3*MILEDIST, 5, 5, 5, 5*MILEDIST, 10, 10, 7*MILEDIST, 10*MILEDIST, 20, 21.0975,
    16*MILEDIST, 30, 20*MILEDIST, 42.195]

export const MAIN_EVENTS = ['100', '200', '400', '800', '1500', '1M', 'Mile', '3000', 'parkrun', '5K', '5000', '5M', '10K', '10000', '10M', 'HM', 'Mar']

export const KEY_EVENTS = ['800', '1500', 'Mile', '3000', 'parkrun', '5K', '5000', '5M', '10K', '10000', '10M', 'HM', 'Mar']

export const ETYPES = ['R', 'T', 'XC', 'MT', 'F', 'PR', 'MR', 'notparkrun']
export const ETYPES_DESCR = ['Road', 'Track', 'XC', 'MT', 'Fell', 'Parkrun', 'Trail', 'Non-Parkrun']

export const AGE_GROUPS: string[] = ["ALL", "V35", "V35-40", "V40", "V40-45", "V45", "V45-50", "V50", "V50-55", "V55", "V55-60", "V60", "V60-65", "V65", "V65-70", "V70", "V70-75", "V75", "V75-80", "V80"]

export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export var Runners_dict: {} = {};
export var Runners_array: Runner[];

export function getRunnersNum() { return Object.keys(Runners_dict).length; }

export function getRunner(rid) {
    return Runners_dict[rid]
}

export function setRunners(runners) {

    Runners_dict = runners;

    var sorted_runners = sortKeys(Runners_dict, 'First', 'Last', true, true);
    Runners_array = [];
    for (var rid in sorted_runners) {
        Runners_array.push(Runners_dict[sorted_runners[rid]]);
    }
}

export class Runner {
    ID: string = '';
    First: string = '';
    Last: string = '';
    AG: string = '';
    Gender: string = '';
    Club: string = '';
    DOB: string = '';
    StartDate: string = '';
    StartYear: number = 0;
    EndYear: number = 0;
    Status: string = '';
}

export class Finisher {
    ID: string = '';
    Pos: number = 0;
    Time: number = 0;
    PB: number = 0;
    MeetingID: number = 0;
    Event: string = '';
    Date: string = '';
    Meeting: string = '';
    Venue: string = '';
    Gender: string = '';
    AG: string = '';
    AGRes: number = 0;
    Year: number = 0;
    AGE: number = 0;
    Rec: number = 0;
    YRec: number = 0;
    EType: string = '';
    Dist: number = 0;
}

export class Meeting {
    MeetingID: number = 0;
    Event: string = '';
    Date: string = '';
    Meeting: string = '';
    Venue: string = '';
    Num: number = 0;
    NumPB: number = 0;
    NumSB: number = 0;   
    NumTop10: number = 0;
    NumYTop10: number = 0; 
    EType: string = '';
}

export interface RaceStats {
    finishers: number;
    meetings: number;
    actives: [];
    types: [];
}

export class RaceSeries {
    name: string = '';
    agres: string = '0';
    missing: number = 0;
    races: {} = {};
    leaderboard: {} = {};
}

export function eventIconText(event, etype) {
    var elem;
    var text;
    switch(etype) {
        case 'PR':
            elem = <img className='icon' src={ParkrunIcon} alt="parkrun" />
            text = '';
            break;
        case 'R':
            elem = <img className='icon' src={RoadIcon} alt="road" />;
            text = event;
            break;
        case 'XC':
            elem = <GrassIcon sx={{color: 'green' }} fontSize='large' />
            text = event.substr(0,event.length-2);
            break;
        case 'MT':
            elem = <img className='icon' src={MultiIcon} alt="multi-terrain" />;
            text = event.substr(0,event.length-2);
            break;
        case 'T':
            elem = <img className='icon' src={TrackIcon} alt="track" />;
            text = event;
            break;
        case 'MR':
            elem = <FilterHdrOutlinedIcon sx={{color: 'brown' }} fontSize='large' />
            text = event.substr(0,event.length-2);
            break;
        case 'F':
            elem = <FilterHdrOutlinedIcon sx={{color: 'brown' }} fontSize='large' />
            text = event.substr(0,event.length-1);
            break;
        default:
            elem = ''
            text = event;
    }
    return [elem, text]
}

export function dateAdd(date: Date, days: number) {
    var retdate = new Date(date);
    retdate.setDate(retdate.getDate()+days);
    return retdate
}

function use_dec(dist: string): boolean {
    return dist in USE_DEC ? true : false;
}


function PosRes(asc) { return asc ? -1 : 1 } 

export function sortKeys(dname, key1, key2, asc1=true, asc2=true) {
    var posres1 = PosRes(asc1);
    var posres2 = PosRes(asc2);
    var keys: string[] = Object.keys(dname)
    var sortedkeys: string[] = keys.sort(function compareFn(a, b) {
        var res1: number = dname[a][key1] < dname[b][key1] ? posres1 : -posres1;
        if (key2 == null)
            return res1;
        if (dname[a][key1] === dname[b][key1])
            return (dname[a][key2] < dname[b][key2]) ? posres2 : -posres2;
        return res1;
        });
    return sortedkeys;
}

export function equalArrays(a1, a2) {
    if (a1.length !== a2.length) {
        return false;
    }
    for (var n=0; n<a1.length; n++) {
        if (a1[n] !== a2[n]) {
            return false;
        }
    }
    return true;
}

export function equalObjects(o1, o2) {
    for(var p in o1){
        if(o1.hasOwnProperty(p)){
            if(o1[p] !== o2[p]){
                return false;
            }
        }
    }
    for(p in o2){
        if(o2.hasOwnProperty(p)){
            if(o1[p] !== o2[p]){
                return false;
            }
        }
    }
    return true;
};

export function formatTime(t, dist) {
    var it = Math.floor(t);
    if (it !== t || use_dec(dist)) {
        if (t < 100) {
            return t.toFixed(2);
        } else {
            var m = Math.floor(it/60).toFixed(0);
            var s = Math.floor(it%60).toFixed(0);
            var c = ((t - it) * 100.0).toFixed(0);
            return m + ":" + pad2(s) + "." + pad2(c);
        }
    } else {
        s = Math.floor(t%60).toFixed(0);
        if (t < 3600) {  // we are not using the 99:59 format (e.g. for HM) but rather 1:39:59
            m = Math.floor(t/60).toFixed(0);
            return m + ":" + pad2(s);
        } else {
            var h = Math.floor(t/3600).toFixed(0);
            m = Math.floor((t%3600)/60).toFixed(0);
            return h + ":" + pad2(m) + ":" + pad2(s);
        }
    }
}

// Always use HH:MM:SS so Excel doesn't think NN:NN is HH:MM ...
export function formatTimeExcel(t, dist) {
    var it = Math.floor(t);
    if (it !== t || use_dec(dist)) {
        if (t < 100) {
            return t.toFixed(2);
        } else {
            var m = Math.floor(it/60).toFixed(0);
            var s = Math.floor(it%60).toFixed(0);
            var c = ((t - it) * 100.0).toFixed(0);
            return m + ":" + pad2(s) + "." + pad2(c);
        }
    } else {
        s = Math.floor(t%60).toFixed(0);
        if (t < 3600) {  // we are not using the 99:59 format (e.g. for HM) but rather 1:39:59
            m = Math.floor(t/60).toFixed(0);
            return m + ":" + pad2(s);
        } else {
            var h = Math.floor(t/3600).toFixed(0);
            m = Math.floor((t%3600)/60).toFixed(0);
            return h + ":" + pad2(m) + ":" + pad2(s);
        }
    }
}

export function formatDate(date: Date, short=false) {
    var datestr = date.getDate() + " " + MONTHS[date.getMonth()];
    return short ? datestr : datestr + " " + date.getFullYear().toString().substring(2);
}

export function formatDateParam(date: Date) {
    return date.getFullYear() + "-" + pad2(date.getMonth()+1) + "-" + pad2(date.getDate());
}

/*
formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed, so we add 1
    const day = date.getDate().toString().padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  } */

export function getLastSaturdayDate() {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 for Sunday, 1 for Monday, ... 6 for Saturday
    const daysUntilLastSaturday = dayOfWeek === 6 ? 0 : dayOfWeek + 1; // If today is Saturday, stay on Saturday, otherwise go back to the last Saturday

    const lastSaturday = new Date(today);
    lastSaturday.setDate(today.getDate() - daysUntilLastSaturday);

    const formattedDate = formatDateParam(lastSaturday);
    return formattedDate;
}

export function formatDateString(date: string, short=false) {
    return formatDate(new Date(date), short);
}

export function formatAGRes(agres: number) {
    return agres > 0.1 ? agres.toFixed(1)+"%" : '';
}

export function formatShortName(athlete) {
    // return athlete.First[0] + ". " + athlete.Last.substring(0, 12);
    return athlete.First[0] + ". " + athlete.Last;
}

export function getPBString(fin: Finisher, simple=true) {
    var pb = "";
    if (fin.PB === 2) {
        pb = "PB"
        if (!simple && fin.YRec > 0)
            pb += ":" + fin.Rec + "/" + fin.YRec;
    } else if (fin.PB === 1) {
        pb = "SB"
        if (!simple && fin.YRec > 0)
           pb += ":" + fin.YRec;
    }
    return pb;
}

export function formatPB(fin: Finisher, simple=true) {
    var style = fin.PB === 2 ?
                {color: 'var(--text-highlight)'} :
                {color: 'var(--text-highlight-2)'};
    return(<td style={style}>{getPBString(fin, simple)}</td>);
}

export function addCell(value: string, header: boolean = false, span: number=1): JSX.Element {
    return span === 1 ?
        (header ? <th>{value}</th> : <td>{value}</td>) :
        (<th colSpan={span}>{value}</th>);
}

export function addCells(cells, values: string[], header: boolean = false): JSX.Element {
    for (var n=0; n<values.length; n++) {
        cells.push(addCell(values[n], header));
    }

    return cells;
}

var API_SESSION: string = "";

export async function initSession() {
  console.log('initSession')
    var sess = await getSession();
    console.log(sess)
    API_SESSION = sess !== null ? "session=" + sess : "";
    console.log("Starting API Session: " + API_SESSION + " isnative: " + ISNATIVE + " OS: " + getOS() + " Host: " + HOST);
    return API_SESSION;
}

export async function setSession(sess): Promise<void> {
    await Preferences.set({
      key: 'session',
      value: sess,
    });

    API_SESSION = "session=" + sess;
    // console.log("Set API_SESSION=" + API_SESSION);
    // console.log("Get Session = " + getSession());
};

export const setSplashScreen = ( runners, finishers?, meetings?) => {
  localStorage.setItem('splash-runners', runners);
  localStorage.setItem('splash-finishers', finishers);
  localStorage.setItem('splash-meetings', meetings);
}

async function getSession(): Promise<any> {
    const item = await Preferences.get({ key: 'session' });
    // console.log('getSession')
    // console.log(item)
    return item.value;    
}

const QUERYROOT = HOST + "/runningapi/"
const FETCH_PARAMS: RequestInit = { method: 'GET' }

// Handle errors from server interactions
function setError(err) {
    /* var text = err && err.message ? err.message : ''; */
}

function handleErrors(response) {
    // console.log(response)
    if (!response.ok) {
        if (response.status >= 500) throw Error("Server Error: " + response.statusText)
        else throw Error(response.statusText);
    } else
        setError(null);

    return response;
}

export function formUrl(apicall) {
    var url = QUERYROOT + apicall;
    if (API_SESSION !== "") {
        if (apicall.indexOf("?") >= 0)
            url = url + "&" + API_SESSION;
        else
            url = url + "?" + API_SESSION;
    }
    return url;
}

export async function serverRequest(apicall) {
    // console.log('server request')
    // console.log(apicall)
    var url = formUrl(apicall);
    return fetch(url, FETCH_PARAMS)
        .then(handleErrors)
        .then(res => {
            // console.log(res)
            return res.json();
        })

    // try {
    //   const res = await fetch(url, FETCH_PARAMS)

    //   return res.json()
    // } catch(err) {
    //   handleErrors(err)
      
    // }
}

export function serverRequestBlob(apicall) {
    var url = formUrl(apicall);
    return fetch(url, FETCH_PARAMS)
        .then(handleErrors)
        .then(res => {
            return res.blob();
        })
}

// Generic setters and getters for Capacitor.Preferences storage API

export const setStorageValue = async (key: string, value: string) => {
  await Preferences.set({
    key,
    value
  })
}

export const getStorageValue = async (key: string) => {
  const { value } = await Preferences.get({ key });
  if (value) {
    return value;
  }
  // return '';

}
