import React from 'react';
import { FormControl, Select, MenuItem, InputLabel, Box, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import { TBar, ABar, ExpandableSection } from './Widgets';
import { FilterDrawer, Filter, FilterPrefs } from './Filter';
import { formatTime, MAIN_EVENTS, KEY_EVENTS, ALL_EVENTS, ETYPES, ETYPES_DESCR, sortKeys,
    Runner, Meeting, RaceStats, RaceSeries, getRunner, getRunnersNum, formatShortName, formatDateString, serverRequest, serverRequestBlob, ISNATIVE } from './Util';

import { MeetingFinishers } from './Races';
import { RunnerSearch } from './Runner';
import { MultiChart } from './Charts';
import '../Running.css';
import { PBMulti } from './Records';
import { GlobalContext } from '../context/GlobalContextProvider';
import MenuIcon from '@mui/icons-material/Menu';

interface H2HProps {}
interface H2HState {
    h2h: {};
    matrix: [];
    meetings: {};
    runners: string[];
    pbs: [];
    loading: boolean;
}

export class H2H extends React.Component<H2HProps, H2HState> {

  static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = { h2h: {}, meetings: {}, runners: [], matrix: [], pbs: [], loading: false }
        this.onAddRunner = this.onAddRunner.bind(this);
        this.onRemoveRunner = this.onRemoveRunner.bind(this);
    }

    componentDidMount() {
        this.loadH2H();
    }

    loadH2H() {
        if (this.state.runners.length < 2)
            return;

        serverRequest("head_to_head?list=" + this.state.runners)
        .then(res => {
            this.setState( {h2h: res.h2h, matrix: res.matrix, meetings: res.meetings} );

            serverRequest("pbs?list=" + this.state.runners)
            .then(res => {
                this.setState( {pbs: res, loading: false} );
            });
        });
    }

    onAddRunner(ID: string) {
        if (ID !== undefined) {
            if (this.state.runners.indexOf(ID) < 0) {
                // making sure it's a new list
                var newlist: string[] = [];
                newlist = newlist.concat(this.state.runners);
                newlist.push(ID);
                this.setState({ runners: newlist, loading: true}, () => {
                    this.loadH2H();
                });
            }
        }
    }

    onRemoveRunner(e) {
        const rID = e.currentTarget.getAttribute('runner-key');

        var ind = this.state.runners.indexOf(rID);
        if (ind >= 0) {
            // making sure it's a new list
            var newlist: string[] = [];
            newlist = newlist.concat(this.state.runners);
            newlist.splice(ind, 1)
            this.setState({ runners: newlist, loading: true}, () => {
                this.loadH2H();
            });
        }
    }

    openMenu(context) {
      context.setMenuOpen(!context.menuOpen);
    }

    render() {
        var h2h = this.state.h2h;
        var meetdict = this.state.meetings;
        var racekeys = sortKeys(meetdict, 'Date', null, false);
        var matrix = this.state.matrix;
        var runners = this.state.runners;
        var nrunners: number = runners.length;

        var appbar = <ABar position="top" >
                <TBar>
            <IconButton sx={{ color: 'var(--paper-color)'}} onClick={() => this.openMenu(this.context)}>
          <MenuIcon fontSize="large"></MenuIcon>
                  </IconButton>
                  <div className='largetext' >Head to Head</div>
                  <Box sx={{ flexGrow: 1}} />
                  <RunnerSearch onSelectRunner={this.onAddRunner} />
                </TBar>
                </ABar>

        var meeting_table = <></>
        var message = <></>
        var matrix_table = <></>
        var chart = <></>
        var pbtable = <></>

        var rows: JSX.Element[] = [];
        var cols: JSX.Element[] = [];

        if (nrunners > 0) {
            // Runners matrix
            cols.push(<td></td>)
            for (var r1=0; r1<nrunners; r1++) {
                var runner1: Runner = getRunner(runners[r1]);
                cols.push(<td className='vertical'>{formatShortName(runner1)}</td>)
            }
            cols.push(<td className='vertical'><b>Totals</b></td>)
            var head = (<tr>{cols}</tr>);

            for (r1=0; r1<nrunners; r1++) {
                runner1 = getRunner(runners[r1]);
                cols = [];
                cols.push(<td style={{textAlign: 'left'}} >
                        <IconButton onClick={this.onRemoveRunner} runner-key={runner1.ID}>
                            <HighlightOffIcon />
                        </IconButton>
                        {formatShortName(runner1)}
                    </td>)
                var won = 0;
                var lost = 0;
                for (var r2=0; r2<nrunners; r2++) {
                    if (!this.state.loading && r1 !== r2) {
                        won += matrix[r1][r2];
                        lost += matrix[r2][r1];
                        cols.push(<td>{matrix[r1][r2] + "-" + matrix[r2][r1]}</td>)
                    } else
                        cols.push(<td>--</td>)
                }
                cols.push(<td><b>{won + "-" + lost}</b></td>)
                rows.push(<tr>{cols}</tr>)
            }
            matrix_table = <div className='card'>
                                <table><thead>{head}</thead><tbody>{rows}</tbody></table>
                            </div>

            chart = <ExpandableSection title="Chart" details="">
                            <MultiChart runners={this.state.runners} />
                        </ExpandableSection>

        }

        if (nrunners > 1 && !this.state.loading) {
            rows = [];
            cols = [];
            cols.push(<th>Date</th>);
            cols.push(<th>Meeting</th>);
            cols.push(<th>Event</th>)
            for (var n=0; n<nrunners; n++) {
                var runner: Runner = getRunner(runners[n]);
                if (runner === undefined)
                    continue; 
                cols.push(<th>{formatShortName(runner)}</th>);
            }
            rows.push(<tr>{cols}</tr>);

            var meeting_rows = racekeys.map((key, ind) => {
                var meeting: Meeting = meetdict[key];
                var rec = h2h[meeting.MeetingID + "-" + meeting.Event];  // unique key for H2H is meetingID-Event
                if (rec === undefined)
                    return <tr></tr>;

                cols = [];
                cols.push(<td>{formatDateString(meeting.Date)}</td>);
                cols.push(<td className='meetingcol'>{meeting.Meeting}<br/>{meeting.Venue}</td>);
                cols.push(<td>{meeting.Event}</td>);

                // Finds winner
                var bestrunner = -1;
                var besttime = 999999;
                for (var n=0; n<nrunners; n++) {
                    if (rec[n+nrunners] > 0) {
                        if (rec[n+nrunners] < besttime) {
                            besttime = rec[n+nrunners];
                            bestrunner = n;
                        }
                    }
                }

                for (n=0; n<nrunners; n++) {
                    if (rec[n+nrunners] > 0) {
                        var style = (n === bestrunner) ? {fontWeight: 'bold'} : {};
                        cols.push(<td key={n} style={style}>{formatTime(rec[n+nrunners], meeting.Event)}</td>);
                    } else
                        cols.push(<td>--</td>);
                }
                return(<tr key={ind}>{cols}</tr>);
            });
            meeting_table = <ExpandableSection title="Results" details={racekeys.length + " races"} >
                        <table><tbody>{rows}{meeting_rows}</tbody></table>
                    </ExpandableSection>

            pbtable = <ExpandableSection title="Personal Bests" details="">
                    <PBMulti pblist={this.state.pbs} runners={this.state.runners} />
                    </ExpandableSection>
        } else {
            message = <h2 style={{padding: '16px'}}>Select two or more runners</h2>
        }

        return (<div>
            {appbar}
            {message}
           <div style={{margin: '16px'}}> {matrix_table} </div>
          <div style={{ paddingTop: '16px', paddingLeft: '16px', paddingRight: '16px' }}>{chart}</div>
          <div style={{ paddingTop: '16px', paddingLeft: '16px', paddingRight: '16px' }}>  {pbtable}</div>
          <div style={{ paddingTop: '16px', paddingLeft: '16px', paddingRight: '16px' }}> {meeting_table} </div>
        </div>)
}
}

interface StatsProps {}
interface StatsState {
    stats: RaceStats;
    mainOnly: boolean;
}

export class Stats extends React.Component<StatsProps, StatsState> {

  static contextType = GlobalContext;
    constructor(props) {
        super(props);
        this.state = { stats: {} as RaceStats, mainOnly: true }
        this.loadStats = this.loadStats.bind(this);
    }

    componentDidMount() {
        this.loadStats(new Filter(0, 0, '', '', '', true, ''), true);
    }

    loadStats(filter: Filter, requery) {
        if (requery) {
            serverRequest("racestats?age_group=" + filter.age_group + "&gender=" + filter.gender)
            .then(res => {
                this.setState( {stats: res, mainOnly: filter.mainOnly } );
            });
        } else {
            this.setState( {mainOnly: filter.mainOnly } );
        }
    }

    static filter_prefs: FilterPrefs = {year: false, month: false,
        event: false, all_event: true, mainOnly: true,
        gender: true, all_gender: true, age_group: true, all_age_group: true, meeting: false };


  openMenu(context) {
    context.setMenuOpen(!context.menuOpen);

  }

    render() {
        var stats = this.state.stats;
        if (!stats.actives || stats.actives.length < 1)
            return <div></div>

        var filter = <FilterDrawer onChange={this.loadStats} prefs={Stats.filter_prefs} start_filter={new Filter()} display_filter={true} />
                
        var maxetypes = ETYPES.length;
        var event_list = ETYPES.concat((this.state.mainOnly ? KEY_EVENTS : MAIN_EVENTS).reverse());
        var pr_ind = event_list.indexOf("parkrun");  // Removes redundant event 
        if (pr_ind >= 0)
            event_list.splice(pr_ind, 1)

        pr_ind = event_list.indexOf("notparkrun");  // Removes redundant event type
        if (pr_ind >= 0) {
            event_list.splice(pr_ind, 1)
            // console.log("Removed notparkrun")
            maxetypes -= 1;
        }

        // console.log(event_list);

        // actives[n] = [year, runners, results] in increasing order from a starting year
        var actives: number[] = stats.actives;
        var num_years = actives.length;
        var end_year = actives[num_years-1][0]

        // Prepares matrix of finishers by events and year (rows = events, cols = years)
        var fin_matrix = new Array(event_list.length).fill(0).map(() => new Array(num_years).fill(0));

        // evtypes[n] = [year, event, results]
        var evtypes = stats.types;
        // console.log(evtypes);

        for (var n=0; n<evtypes.length; n++) {
            var col = end_year - evtypes[n][0];
            if (col < 0 || col >= num_years)
                continue; 

            var row = event_list.indexOf(evtypes[n][1]);
            if (row >= 0) {
                fin_matrix[row][col] = evtypes[n][2];
            }
        }

        // We travel years and events in reverse to show the latest and the longest at the top
        var rev_actives = actives.slice().reverse();
        var years_row = <tr><th></th>{rev_actives.map((act, index) => { return (<th key={index}>{act[0]}</th>) })}</tr>
        var runners_row = <tr><th className='sticky-col first-col'>Runners</th>{rev_actives.map((act, index) => { return (<td key={index}>{act[1]}</td>) })}</tr>
        var results_row = <tr><th className='sticky-col first-col'>Results</th>{rev_actives.map((act, index) => { return (<td key={index}>{act[2]}</td>) })}</tr>

        var rows = fin_matrix.map((row, row_ind) => {
            var cols = row.map((rec, col_ind) => {return <td>{rec > 0 ? rec : '--'}</td>});
            var row_label = (row_ind < maxetypes) ? ETYPES_DESCR[row_ind] : event_list[row_ind];
            var style = (row_ind < maxetypes) ? {'backgroundColor' : 'var(--secondary-color)'} : {};
            // console.log("Row " + row_ind + " " + ETYPES_DESCR[row_ind] + " " + event_list[row_ind] + " " + maxetypes);
            return(<tr key={row_ind}>
                        <th className='sticky-col first-col' style={style}>{row_label}</th>
                        {cols}
                    </tr>);
        });

        var empty_row = <tr><th className='sticky-col first-col'></th><td colSpan={num_years}></td></tr>
        var type_table = <table className='bicolortable'><thead>{years_row}</thead><tbody>{runners_row}{results_row}{empty_row}{rows}</tbody></table>
        var mainstats = <p><b>{getRunnersNum()}</b> Runners | <b>{stats.meetings}</b> Meetings | <b>{stats.finishers}</b> Finishers</p>
        var appbar = <ABar position="top" >
                <TBar>
            <IconButton sx={{ color: "var(--paper-color)" }} onClick={() => this.openMenu(this.context)}>
                      <MenuIcon fontSize="large"></MenuIcon>
                  </IconButton>
                    <div className='largetext'>Club Stats</div>
                    <Box sx={{ flexGrow: 1}} />
                    {filter}
                </TBar>
                </ABar>

        return <div>
            {appbar}
            <div style={{padding: '16px'}}>
            {mainstats}
            <h2>Number of Results per Year and Event</h2>
            <div>{type_table}</div>
            </div>
        </div>
    }
}

interface SeriesProps {
    selSerie: number;
    awards: number;
    onEsc;
}

interface SeriesState {
    series: RaceSeries;
    param: string;
    selRaceID: number;
    selRunnerID: string;
}

export class Series extends React.Component<SeriesProps, SeriesState> {
    constructor(props) {
        super(props);
        var defParam = this.props.awards === 0 ? "M" : "ALL";
        this.state = { series: {} as RaceSeries, param: defParam, selRaceID: 0, selRunnerID: '' }
        this.handleParam = this.handleParam.bind(this);
        this.onSelectRace = this.onSelectRace.bind(this);
        this.onPrint = this.onPrint.bind(this);
        // console.log("Series awards=" + this.props.awards + " param=" + this.state.param + " type=" + typeof(this.props.awards))
    }

    handleParam(e) {
        this.setState({param: e.target.value}, () => { this.loadSeries(); });
    }

    onSelectRace = (e) => {
        const ID = Number(e.currentTarget.getAttribute('data-key'));
        const rID = e.currentTarget.getAttribute('runner-key');
        this.setState( { selRaceID: ID === this.state.selRaceID ? 0 : ID, selRunnerID: rID === this.state.selRunnerID ? 0 : rID } );
    }
    
    componentDidMount() {
        this.loadSeries();
    }

    onPrint() {
        serverRequestBlob("printseries?id=" + this.props.selSerie + "&param=" + this.state.param)
        .then(blob => {
            var url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            const fname = "Champs " + this.props.selSerie + " " + this.state.param + ".pdf";
            link.href = url;
            link.setAttribute('download', fname);
            link.click();
        });
    }

    loadSeries() {
        // console.log("Load series param=" + this.state.param)

        // TBD: escape param or 'MV60+' becomes 'MV60 '
        serverRequest("series?id=" + this.props.selSerie + "&param=" + this.state.param)
        .then(res => {
            this.setState( {series: res} );
        });
    }

    render() {
        if (Object.keys(this.state.series).length < 1) {
            return <></>
        }

        // console.log(this.state.series);
        var leaderboard = this.state.series.leaderboard;
        var races = this.state.series.races;

        var award = parseInt(this.state.series.agres);
        if (award === undefined) award = 2;

        var style = {
            color: 'var(--primary-color)',
            fontSize: '1.4rem',
            }

        var print_button = !ISNATIVE ? <IconButton onClick={this.onPrint}>
                                            <FileDownloadOutlinedIcon fontSize='large'/>
                                        </IconButton>
                                    : <></>

        // Award:
        // 0: Male/Female 
        // 1: ALL/Male/Female
        // 2: ALL - Single ranking, awarding overall AG Res, top 3 male/female, top age groups male/female, top AG Res male/female (for single competition champs)
        // 3: ALL/Invididual Age Groups (MSEN, WSEN, MV40, WV40, etc. we award 1st, 2nd, 3rd for all categories)
        var gender_filter;
        switch(award) {
            case 0:
                gender_filter = <FormControl className='noprint' margin='dense' sx={{width: '20ch'}}>
                                    <InputLabel sx={style}>Leaderboard</InputLabel>
                                    <Select value={this.state.param} label="Gender" onChange={this.handleParam} sx={style}>
                                        <MenuItem key='W' value='W'>Women</MenuItem>
                                        <MenuItem key='M' value='M'>Men</MenuItem>
                                    </Select>
                            </FormControl>
                break;
            case 1:
                gender_filter = <FormControl className='noprint' margin='dense' sx={{width: '20ch'}}>
                                    <InputLabel sx={style}>Leaderboard</InputLabel>
                                    <Select value={this.state.param} label="Gender" onChange={this.handleParam} sx={style}>
                                        <MenuItem key='ALL' value='ALL'>Age Graded</MenuItem>
                                        <MenuItem key='W' value='W'>Women</MenuItem>
                                        <MenuItem key='M' value='M'>Men</MenuItem>
                                    </Select>
                            </FormControl>
                break;
            case 3:
                gender_filter = <FormControl className='noprint' margin='dense' sx={{width: '20ch'}}>
                                    <InputLabel sx={style}>Leaderboard</InputLabel>
                                    <Select value={this.state.param} label="Gender" onChange={this.handleParam} sx={style}>
                                        <MenuItem key='ALL' value='ALL'>Age Graded</MenuItem>
                                        <MenuItem key='WSEN' value='WSEN'>Women SEN</MenuItem>
                                        <MenuItem key='MSEN' value='MSEN'>Men SEN</MenuItem>
                                        <MenuItem key='WV40' value='WV40'>Women V40</MenuItem>
                                        <MenuItem key='MV40' value='MV40'>Men V40</MenuItem>
                                        <MenuItem key='WV50' value='WV50'>Women V50</MenuItem>
                                        <MenuItem key='MV50' value='MV50'>Men V50</MenuItem>
                                        <MenuItem key='WV60' value='WV60'>Women V60</MenuItem>
                                        <MenuItem key='MV60' value='MV60'>Men V60</MenuItem>
                                        <MenuItem key='WV70+' value='WV70+'>Women V70+</MenuItem>
                                        <MenuItem key='MV70+' value='MV70+'>Men V70+</MenuItem>
                                    </Select>
                            </FormControl>
                break;
            case 2:
            gender_filter = <></>
        }

        // Determines if we are in a generic or specific race context
        var racekeys = Object.keys(races);
        var is_generic = true;
        racekeys.map((key, raceind) => {
            var race = races[key]
            if (race.length >= 5)  // race with all attributes (Date, Event, Meeting, Venue, Priority)
                is_generic = false;
            return is_generic;
        });

        // Sort by event order or by date, depending on generic/specific races in scope
        if (is_generic)
            racekeys.sort(function compareFn(a, b) { return (ALL_EVENTS.indexOf(races[a][1]) < ALL_EVENTS.indexOf(races[b][1])) ? -1 : 1; });
        else
            racekeys.sort(function compareFn(a, b) { return (races[a][0] < races[b][0]) ? -1 : 1; });

        var rows;
        var racetable = <div></div>
        var head = <></>
        if (!is_generic) {
            rows = racekeys.map((key, raceind) => {
                var race = races[key]
                var style = race[5] ? {backgroundColor: 'purple'} : {}
                return <tr><th style={style}>{"R" + (raceind+1)}</th><td>{formatDateString(race[2])}</td><td>{race[1]}</td><td>{race[3]}</td><td>{race[4]}</td><td>{race[5] ? "Yes" : "No"}</td></tr>
            });
            head = <tr><th>#</th><th>Date</th><th>Event</th><th>Meeting</th><th>Venue</th><th>Priority</th></tr>
            racetable = <table><thead>{head}</thead><tbody>{rows}</tbody></table>
        }

        if (award !== 2) {
            var cells = racekeys.map((key, raceind) => {
                var race = races[key]
                var style = race[5] ? {backgroundColor: 'purple'} : {}
                return(<th colSpan={2} style={style}>{is_generic ? race[1] : "R" + (raceind+1)}</th>);
            });
            head = <tr><th>Pos</th><th>Name</th><th>AG</th><th>Score</th><th>Avg</th>{cells}<th>Reason</th></tr>;
        } else
            head = <tr><th></th><th>Pos</th><th>Name</th><th>AG</th><th>Time</th><th>AG Res</th><th>Reason</th></tr>;

        // Leaderboard format (dictionary):
        // leaderboard[runid] = [aggregate, specific]
        //      aggregate = [runid, points, nscores, qualified, age_group, award, reason]
        //      specific = [[valid, metric, score, meetID]]

        var leadkeys = Object.keys(leaderboard)

        if (award !== 2) {
            // Sort by aggregate points and qualified flag
            // If both runners qualify or both don't qualify, compare with score, otherwise the qualified wins anyway
            leadkeys.sort(function compareFn(a, b) {
                var a_aggr = leaderboard[a][0]
                var b_aggr = leaderboard[b][0]
                if (a_aggr[3] === b_aggr[3])
                    return (a_aggr[1] < b_aggr[1]) ? 1 : -1;
                return a_aggr[3] < b_aggr[3] ? 1 : -1;
            });
        } else {
            // Sort by points only
            leadkeys.sort(function compareFn(a, b) {
                var a_aggr = leaderboard[a][0]
                var b_aggr = leaderboard[b][0]
                return (a_aggr[1] < b_aggr[1]) ? 1 : -1;
            });
        }

        var classes;
        rows = leadkeys.map((key, leadind) => {
            var aggr = leaderboard[key][0];
            var specific = leaderboard[key][1];

            var runner: Runner = getRunner(aggr[0]);
            var fincells: JSX.Element[] = [];

            for (var n=0; n<specific.length; n++) {
                var race = specific[n]

                classes = (aggr[3] && race[0]) ? '' : 'disabledcell';
                if (award !== 2) {
                    if (race[3] > 0) {
                        // TBD: we should access the actual event for proper time formatting
                        var perf = this.state.param === "ALL" ? race[2].toFixed(1) + "%" : formatTime(race[1], '5K');
                        fincells.push(<td className={'link ' + classes} onClick={this.onSelectRace} data-key={race[4]} runner-key={runner.ID}>{perf}</td>);
                        fincells.push(<td className={'borderright ' + classes}><b>{race[3]}</b></td>);
                    } else {
                        fincells.push(<td colSpan={2} className='borderright'>--</td>);
                    }
                } else {
                    fincells.push(<td className={'link ' + classes} onClick={this.onSelectRace} data-key={race[4]} runner-key={runner.ID}>{formatTime(race[1], '5K')}</td>);
                    fincells.push(<td className={'link ' + classes} onClick={this.onSelectRace} data-key={race[4]} runner-key={runner.ID}>{race[2].toFixed(1) + "%"}</td>);
                }
            }

            classes = aggr[3] ? {} : 'disabledcell';
            var scorestyle = {} // {fontSize: '1.6rem'}

            if (award !== 2) {
                var avg = aggr[2] >= 1 ?  (aggr[1] / aggr[2]).toFixed(1) : '';
                return <tr>
                            <td>{aggr[5]}</td>
                            <td>{runner.First + " " + runner.Last}</td>
                            <td>{aggr[4]}</td>
                            <td className={classes} style={scorestyle}><b>{aggr[1]}</b></td>
                            <td className={'borderright ' + classes} >{avg}</td>
                            {fincells}
                            <td>{aggr[6]}</td>
                            </tr>
            } else {
                return <tr>
                            <td>{aggr[5]}</td>
                            <td>{aggr[7]}</td>
                            <td>{runner.First + " " + runner.Last}</td>
                            <td>{runner.Gender + " " + aggr[4]}</td>
                            {fincells}
                            <td>{aggr[6]}</td>
                            </tr>
            }
        });

        var race = <></>
        // var sh = {}
        if (this.state.selRaceID !== 0) {
            race = <div style={{marginTop: '5px'}}>
                <MeetingFinishers raceID={this.state.selRaceID} runnerID={this.state.selRunnerID} showDescr={true} />
                </div>
            // sh = {maxHeight: '40vh'}
        }

        var table = <table className='bicolortable'><thead>{head}</thead><tbody>{rows}</tbody></table>

        var title;
        if (award === 2) {
            title = this.state.series.name;
        } else {
            var racestr = this.state.series.missing > 1 ? " races" : " race";
            var missing = this.state.series.missing > 0 ? " - " + this.state.series.missing + racestr + " to go" : '';
            title = this.state.series.name + " - " + (this.state.param === 'M' ? 'Men' : (this.state.param === 'W' ? 'Women' : 'Age Graded')) + missing;
        }

        return <div>
                {gender_filter}
                {print_button}
                <h2>{title}</h2>
                <div style={{marginBottom: '10px'}}>{racetable}</div>
                {table}
                {race}
            </div>
    }
}

interface SeriesSelectorProps {}
interface SeriesSelectorState {
    list: {};
    selSerie: number;
    awards: number;
}

export class SeriesSelector extends React.Component<SeriesSelectorProps, SeriesSelectorState> {

  static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = { list: {}, selSerie: 0, awards: 0 }
        this.onSelectSerie = this.onSelectSerie.bind(this);
        this.onEsc = this.onEsc.bind(this);
    }

    onSelectSerie(e) {
        const ID = Number(e.currentTarget.getAttribute('data-key'));
        const awards = Number(e.currentTarget.getAttribute('awards-key'));
        this.setState({ selSerie: ID, awards: awards })
    }

    onEsc(e) {
        this.setState({ selSerie: 0});
    }

    componentDidMount() {
        this.loadSeries();
    }

    loadSeries() {
        serverRequest("series")
        .then(res => {
            this.setState( {list: res} );
        });
    }

    openMenu(context) {
        context.setMenuOpen(!context.menuOpen);
    }

    render() {
        var page = <div></div>
        var leftmenu = <></>
        if (this.state.selSerie) {
            page = <Series selSerie={this.state.selSerie} awards={this.state.awards} onEsc={this.onEsc} />
            leftmenu = <IconButton onClick={this.onEsc} style={{display: 'inline', marginRight: '10px', color: 'var(--light-text-color)'}}>
                            <ArrowBackIosIcon />
                    </IconButton>
        } else {
            var list = this.state.list;
            var keys = sortKeys(this.state.list, 'ID', null, false);
            var rows = keys.map((key, ind) => {
                var serie = list[key];
                return <tr onClick={this.onSelectSerie} data-key={serie.ID} awards-key={serie.Awards}>
                            <td>{serie.Year}</td><td className='descrcol'><b>{serie.Name}</b></td><td className='descrcol'>{serie.Descr}</td>
                    </tr>
            });

            var head = <tr><th>Year</th><th>Competition</th><th>Description</th></tr>
            page = <div className='limitcontainer'><table className='fixedtable link'><thead>{head}</thead><tbody>{rows}</tbody></table></div>
        }

        var appbar = <ABar position="top" >
                    <TBar>
                        {leftmenu}
            <IconButton sx={{ color: 'var(--paper-color)'}} onClick={() => this.openMenu(this.context)}>

              <MenuIcon fontSize="large"></MenuIcon>
            </IconButton>
                        <div className='largetext' >Club Championships</div>
                        <Box sx={{ flexGrow: 1}} />
                    </TBar>
                    </ABar>

        return (
          <div>
            {appbar}
            <div style={{padding: '16px'}}>{page}</div>
          </div>)
    }
}
